import React from 'react';
import { useReportContext } from '@hubins/components/ReportContext';
import { usePremiumTransactions } from 'queries/transactions';
import { LoadingBox } from 'components/LoadingIndicators';
import WithdrawalAndPremiumCard from '../WithdrawalAndPremiumCard';
import Heading from 'components/Heading';
import ReadMore from 'components/Accordeon/ReadMore';

type Props = {
  portfolioUuid?: string;
};

const OverviewPremiumTransactions = ({portfolioUuid: propsPortfolioUuid}: Props) => {

  const { portfolioUuid } = useReportContext();
  const { data, isLoading, isFetched, refetch } = usePremiumTransactions(
    propsPortfolioUuid
      ? propsPortfolioUuid
      : portfolioUuid
    );

  if (isLoading) return <LoadingBox />;

  return (
    <>
      {(isFetched && data.length > 0) && (
        <>
          <Heading size="4">Insättningar</Heading>
          {/* Take the first 3 transactions here and use ReadMore for the rest */}
          {data?.slice(0, 3).map((transaction: any) => (
            <WithdrawalAndPremiumCard key={transaction.uuid} {...transaction} refetch={refetch} />
          ))}
          {data?.length > 3 && (
            <div className="s-bottom-xl">
            <ReadMore
              className=""
            hiddenContent={
              data?.slice(3).map((transaction: any) => (
                <WithdrawalAndPremiumCard key={transaction.uuid} {...transaction} refetch={refetch} />
              ))
              }
            />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OverviewPremiumTransactions;