import React, { useState } from 'react';
import __, { __route } from 'localisation';
import Modal from 'components/Modal';
import Wrapper from '@hubins/components/Wrapper';
import Heading from 'components/Heading';
import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button';
import { Columns, Column } from 'components/Columns';
import './index.scss';

const sources = [
  {
    title: 'Överskott lön',
    icon: 'check payment sign',
    type: 'salary'
  },
  {
    title: 'Utdelning',
    icon: 'cash payment coins',
    type: 'dividend'
  },
  {
    title: 'Ägande i eller försäljning av fastighet',
    icon: 'real estate insurance dollar hand',
    type: 'property'
  },
  {
    title: 'Investeringar',
    icon: 'Performance Money Increase',
    type: 'investment_options'
  },
  {
    title: 'Ägande i eller försäljning av företag',
    icon: 'real estate action building dollar',
    type: 'sale_company'
  },
  {
    title: 'Arv, gåvor eller Annat',
    icon: 'Single Neutral Actions Money by Streamlinehq',
    type: 'inheritance_gift'
  },
];

const investmentSources = [
  {
    title: 'Direktinvesteringar',
    icon: 'Performance Money Increase',
    type: 'direct_investments'
  },
  {
    title: 'ISK, depå, kapitalförsäkring eller pensionsutbetalning',
    icon: 'Performance Money Increase',
    type: 'profits_investment'
  },
  {
    title: 'Investeringar i företag',
    icon: 'real estate action building dollar',
    type: 'sale_company'
  },
  {
    title: 'Investeringar i fastighet',
    icon: 'real estate insurance dollar hand',
    type: 'property'
  },
  {
    title: 'Annat',
    icon: 'resize expand',
    type: 'inheritance_gift'
  },
];

const companySources = [
  {
    title: 'Resultat från daglig verksamhet',
    icon: 'check payment sign',
    type: 'company_profits',
  },
  {
    title: 'Investeringar',
    icon: 'cash payment coins',
    type: 'investment_options',
  },
  {
    title: 'Ägande i eller försäljning av fastighet',
    icon: 'real estate insurance dollar hand',
    type: 'company_property',
  },
  // New
  {
    title: 'Ägande i eller försäljning av andra företag',
    icon: 'real estate action building dollar',
    type: 'company_sale_company',
  },
  {
    title: 'Utdelning',
    icon: 'Common File Text by Streamlinehq',
    type: 'company_dividend',
  },
  {
    title: 'Annat',
    icon: 'Common File Text by Streamlinehq',
    type: 'company_other',
  },
  {
    title: 'Aktieemission',
    icon: 'cash payment bag',
    type: 'issue_stock',
  },
  {
    title: 'Obligationsemission eller företagslån',
    icon: 'Cash Payment Bag 1',
    type: 'issue_bonds_loan',
  },
  {
    title: 'Finansiering från moderbolag ',
    icon: 'Cash Payment Bills',
    type: 'company_financing',
  },
];

const companyInvestmentSources = [
  {
    title: 'Vinst från tidigare investeringar',
    icon: 'cash payment coins',
    type: 'company_profits_investment',
  },
  {
    title: 'Investeringar i intresse- & dotterbolag ',
    icon: 'real estate action building dollar',
    type: 'company_sale_company',
  },
  {
    title: 'Direktinvesteringar',
    icon: 'Common File Text by Streamlinehq',
    type: 'company_direct_investments',
  },
  {
    title: 'Investeringar i fastighet ',
    icon: 'real estate insurance dollar hand',
    type: 'company_property',
  },
  {
    title: 'Annat',
    icon: 'Common File Text by Streamlinehq',
    type: 'company_other',
  },
];

const singleResponseKeys = [
  'company_profits',
  'salary',
  'issue_stock',
  'issue_bonds_loan',
  'company_financing',
];

const SourceButton = ({ title, icon, type, portfolioUuid, profileUuid, setHasSelectedInvestment, existingSources, backoffice }) => {

  const singleResponse = singleResponseKeys.includes(type) && existingSources.find(source => source.type === type);

  const getUrl = () => {
    if (backoffice) {
      return singleResponse ? `/sof/edit/${type}/${singleResponse.uuid}/${profileUuid}`
        : `/sof/create/${type}/${profileUuid}`;
    }
    return `${__route('sof')}/${portfolioUuid}/${type}/${singleResponse ? singleResponse.uuid : ''}`;
  };

  const url = getUrl();

  const investmentOption = type === 'investment_options';

  return (
    <div className='source_button'>
      <div className="s-bottom-xl">
        <Icon icon={icon} size={24} color="primary" />
        <Heading size="5" className="flex-center--horizontal space-between">
          {title}
        </Heading>
      </div>
      <div>
        <Button
          onClick={
            investmentOption
              ? () => { setHasSelectedInvestment(true); }
              : undefined
          }
          href={
            investmentOption
              ? undefined
              : url}
          className="secondary s-top-xl"
          icon="add"
          iconColor="primary"
        >
          Lägg till
        </Button>
      </div>
    </div>
  );
};

const AddSourceModal = ({ open, premium, callback, portfolioUuid, registration, profileUuid, isCompany, existingSources, backoffice }) => {

  const [hasSelectedInvestment, setHasSelectedInvestment] = useState(false);

  const subInvestmentSources = isCompany ? (companyInvestmentSources) : (investmentSources);
  const baseSources = isCompany ? (companySources) : (sources);
  const theSources = hasSelectedInvestment ? (subInvestmentSources) : (baseSources);

  return (
    <Modal closeButton shouldCloseOnOverlayClick open={open} callback={callback}>
      <Wrapper>
        <Columns>
          <Column s="12">
            {hasSelectedInvestment ? (
              <>
                <Heading size="3" className="s-bottom-s">
                  Är källan någon av följande?
                </Heading>
                <Button
                  onClick={() => setHasSelectedInvestment(false)}
                  icon="arrow left 1"
                  iconLeft
                  className='s-bottom-md'
                >
                  Tillbaka
                </Button>
              </>
            ) : (
              <Heading size="3" className="s-bottom-l">
                  Lägg till ny källa
              </Heading>
            )}

          </Column>
          {theSources.map((source, index) => (
            <Column xs="12" s="6" m="6" l="4" key={`source_button-${index}`}>
              <SourceButton
                title={source.title}
                icon={source.icon}
                premium={premium}
                type={source.type}
                portfolioUuid={portfolioUuid}
                registration={registration}
                profileUuid={profileUuid}
                setHasSelectedInvestment={setHasSelectedInvestment}
                existingSources={existingSources}
                isCompany={isCompany}
                backoffice={backoffice}
              />
            </Column>
          ))}
        </Columns>
      </Wrapper>
    </Modal>
  );
};

export default AddSourceModal;
