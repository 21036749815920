import React, { useState, useCallback, ChangeEvent, forwardRef } from 'react';
import Heading from 'components/Heading';
import ErrorMessage from 'components/ErrorMessage';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import { addSpacesTovalue } from './useCurrency';
import './index.scss';

export interface InputProps {
  title?: string;
  label?: string;
  labelDescription?: string;
  description?: string;
  className?: string;
  locked?: boolean;
  value?: string | number;
  name?: string;
  disabled?: boolean;
  id?: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  type?: string;
  inputType?: string;
  suffix?: string;
  error?: string;
  callback?: (value: string | number) => void;
  controlled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  tooltip?: any;
  onBlur?: (e: any) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;

}

const Input = forwardRef((props: InputProps, ref) => {

  const {
    title,
    description,
    className,
    label,
    labelDescription,
    locked = false,
    value: propsValue,
    name,
    disabled = false,
    id,
    min,
    max,
    step,
    placeholder,
    pattern,
    required,
    type = 'text',
    suffix,
    error: propsError,
    callback,
    controlled = false,
    inputRef,
    tooltip,
    onBlur
  } = props;

  const [value, setValue] = useState((propsValue || propsValue === 0) ? propsValue : '');
  const [focused, setFocused] = useState(false);

  const throwError = !focused && propsError;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!controlled) {
      setValue(newValue);
    }
    if (callback) {
      callback(newValue);
    }
  }, [callback, controlled]);

  const handleOnBlur = useCallback((e: any) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }, [value]);

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  return (
    <>
      {title && (
        <Heading element="h3" size={4} className="s-bottom-sm">{title}</Heading>
      )}
      {description && (
        <div className="clarification s-bottom-md p fc">{description}</div>
      )}
      <label className={className}>
        <div className='label-wrapper'>
          {label && (
            <div className="label">{label}</div>
          )}
          {labelDescription && (<div className="label c-neutral-4">{labelDescription}</div>)}
        </div>
        {locked ? (
          <p><strong>
            {props.inputType === 'currency'
              ? addSpacesTovalue((propsValue || propsValue === 0)
                ? propsValue
                : '')
              : (propsValue || propsValue === 0)
                ? propsValue
                : ''}
          </strong></p>
        ) : (
          <div className={`input__wrap ${throwError ? 'input__wrap--error' : ''}`}>
            <input
              ref={inputRef}
              value={controlled ? propsValue : value}
              name={name}
              disabled={disabled}
              id={id}
              min={min}
              max={max}
              step={step}
              className={`${throwError ? 'input error' : 'input'}`}
              placeholder={placeholder}
              pattern={pattern}
              required={required}
              type={type}
              onChange={handleChange}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
            />
            {suffix && (
              <div className="input__suffix">{suffix}</div>
            )}
          </div>
        )}
        {tooltip && (<SidebarTrigger
          text={tooltip}
          id={`${id}_tooltip`}
          icon="question"
        />)}
        </label>
      <ErrorMessage text={propsError} showError={throwError} />
    </>
  )
});

export default Input;