import React, { useState, useEffect } from 'react';
import { Menu, MenuLink, MenuDropdown, MainMenu, SubMenu } from 'modules/Menu';
import { __, __route } from 'localisation';
import ErrorBoundary from 'components/ErrorBoundary';
import './index.scss';
import { useFeature } from '@growthbook/growthbook-react';

export const LoggedinMenu = ({
  logo,
  name = '',
  path,
  ...props
}) => {
  const [unread, setUnread] = useState(props.unread);
  const [showReportMenu, setShowReportMenu] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showTransferMenu, setShowTransferMenu] = useState(false);

  const INVEST = true;
  const TOF = useFeature('tof').on;

  const reportMenuSlugs = [
    __route('root'),
    __route('holdings'),
    __route('report'),
    __route('transactions'),
    __route('yearly_report'),
    __route('savedDocuments'),
  ];

  const transferMenuSlugs = [
    __route("deposit"),
    __route("withdrawal"),
  ];

  useEffect(() => {
    setShowReportMenu(reportMenuSlugs.includes(path));
    setShowTransferMenu(transferMenuSlugs.includes(path));
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }, [path]);

  useEffect(() => {
    setUnread(unread);
  }, [unread]);

  return (
    <Menu logo={logo} rootUrl={__route('root')}>
      <MainMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        logo={logo}
        rootUrl={__route('root')}
      >
        <MenuLink
          main
          to={__route('root')}
          label="Mina konton"
          path={path}
          className
          childPaths={reportMenuSlugs}
        />
        <SubMenu
          isExpanded={showReportMenu}
          path={path}
          paths={[
            {
              path: __route('root'),
              label: __('overview'),
            },
            {
              path: __route('holdings'),
              label: __('holdings'),
            },
            {
              path: __route('transactions'),
              label: __('transactions'),
            },
            {
              path: __route('report'),
              label: __('report'),
            },
            {
              path: __route('yearly_report'),
              label: __('yearly_report'),
            },
            {
              path: __route('savedDocuments'),
              label: __('documents'),
            },
          ]}
        />
        {INVEST
          && (
            <MenuLink
              main
              to={__route('invest')}
              label={__('marketplace_title')}
              path={path}
              className="gtm_menu_invest_button"
            />
          )}
        {TOF && (
          <MenuLink main to={__route('transfer')} label={__('type_presec')} path={path} />
        )}
        <MenuLink main to={__route('register_insurance')} label={__('register_insurance')} path={path} />
        <MenuLink
          main
          onClick={() => setShowTransferMenu(!showTransferMenu)}
          label={__('transfer')}
          path={path}
          className
          childPaths={transferMenuSlugs}
          selected={showTransferMenu}
        />
        <SubMenu
          isExpanded={showTransferMenu}
          path={path}
          paths={[
            {
              path: __route('deposit'),
              label: __('deposit'),
            },
            {
              path: __route('withdrawal'),
              label: __('withdrawal'),
            },
          ]}
        />
      </MainMenu>
      <ErrorBoundary>
        <MenuDropdown
          setMobileMenuOpen={setMobileMenuOpen}
          name={name}
          links={[
            {
              to: __route('logout'),
              label: __('logout'),
              // color: 'c-danger',
              icon: 'sign_out',
            },
            {
              to: __route('settings'),
              label: __('profile_settings'),
            },
            {
              to: __route('aptitude_tests'),
              label: __('aptitude_tests'),
            },
            {
              to: __route('savedDocuments'),
              label: __('saved_documents'),
            },
            {
              to: __route('manage_sof'),
              label: __('sof'),
            },
          ]}>
        </MenuDropdown>
      </ErrorBoundary>
    </Menu>
  );
};

export default LoggedinMenu;
