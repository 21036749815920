import React from 'react';
import Heading from 'components/Heading';
import Icon from '@hubins/components/IconNew'
import Badge from '@hubins/components/Badge';
import { __route } from 'localisation';
import { getMoney } from 'helpers';
import '../WithdrawalAndPremiumCard/index.scss';
import __ from 'localisation';
import { getClientTransactionStatus } from 'helpers/transactions';


const statusTranslations = {
  'draft': __('draft'),
  'pending': __('under_review'),
  'ready-for-execution': __('under_review'),
  'pending_fa_booking': __('under_review'),
  'pending_fa_tax_booking': __('under_review'),
  'completed': __('completed'),
  'OK': __('completed')
}

const badgeColorByStatus = {
  'draft': 'warning',
  'pending': 'info',
  'completed': 'success',
  'OK': 'success'
}

type Props = {
  type: string,
  amount: number,
  created_at: string,
  portfolio: any,
  status: string,
  uuid: string,
  currency: string,
  security: any,
  batch: any,
}

const TOFCard = ({ amount, created_at, portfolio, status, currency, security, batch }: Props) => {

  return (
    <div className="transaction-card">
      <div className='transaction-card__header'>
        <div className='transaction-card__left'>
          <Icon icon="Deposit" color="primary" size={24} />
          <Heading size="5">{__('type_presec')}</Heading>
        </div>
      </div>
      <Badge color={badgeColorByStatus[status] || 'info'}>
        {getClientTransactionStatus({ status, batch })}
      </Badge>
      <p>
        <strong>{__('date')}:</strong> {new Date(created_at).toISOString().substring(0, 10)} <br />
        <strong>{__('portfolio')}:</strong> {portfolio.short_name} <br />
        <strong>{__('payment_amount')}:</strong> {getMoney(amount / 100, currency)} <br />
        <strong>{__('security')}:</strong> {security?.name}
      </p>
    </div>
  )

};

export default TOFCard;
