import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';

const VersionHandler = ({ platform }) => {

  const [needUpdate, setNeedUpdate] = useState(false);

  const checkVersion = async () => {
    if (
      import.meta.env.VITE_MODE === "local" ||
      import.meta.env.VITE_MODE === "development"
    ) return false;

    const config = {
      headers: {
        'Version': VITE_VERSION,
        'Application': platform,
      }
    };
    middleman.get(`/version`, config)
      .catch((e) => {
        if (e.response && e.response.status === 418) {
          setNeedUpdate(true);
        }
      });
  };

  const updateBrowser = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    checkVersion();
  }, []);

  return <>
    {needUpdate && (
      <Modal
        open
      >
        <Heading size="3">
          {window.location.hostname} har uppdaterats
        </Heading>
        <p>Klicka på knappen nedanför för att uppdatera.</p>
        <div className="s-top-m">
          <Button
            className="cta"
            onClick={updateBrowser}
          >
            Uppdatera
          </Button>
        </div>
      </Modal>
    )}
  </>;

};

export default VersionHandler;
