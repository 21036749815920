import React, { useState, useEffect, useRef } from 'react';
import __ from "localisation";
import ProgressTabs from "components/ProgressTabs";
import Create from './Create';
import Source from './Source';
import SourceOfWealth from './SourceOfWealth';
import { LoadingBox } from 'components/LoadingIndicators';
import useTabs from 'hooks/useTabs';
import { useTransactions } from 'queries/transactions';
import middleman from 'helpers/middleman';

const pageTabs = [
  'init',
  'sow',
  'sources',
];

const Deposit = ({ match }) => {
  const { portfolioUuid } = match.params;
  const [selected, setSelected] = useState(1);
  const [transaction, setTransaction] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [sources, setSources] = useState(false);
  const [isHighRisk, setIsHighRisk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deposits, setDeposits] = useState(0);
  const [totalAvailableAmount, setTotalAvailableAmount] = useState(0);

  useTabs(setSelected, selected, pageTabs);
  const { data: transactions, isLoading: transactionsLoading } = useTransactions(portfolioUuid, 'draft');

  const fetchPortfolio = () => {
    return middleman.get(`/portfolio/${portfolioUuid}`).then(response => response.data);
  };

  const fetchSourceOfFunds = () => {
    return middleman.promiseGet(`/source-of-funds/show/${portfolioUuid}`).then(r => r.data);
  };

  const fetchRisk = () => {
    return middleman
      .get(`/risk-assessment/${portfolioUuid}`)
      .then(res => {
        return res.data;
      });
  };

  const fetchDepositLimit = () => {
    return middleman.get(`/v1/fa/deposit/stats/${portfolioUuid}`).then(res => res.data);
  };

  const calculateAvailableAmount = (allSources, shouldBeHighRisk) => {
    const availableAmountKey = shouldBeHighRisk ? 'highRiskAvailableAmount' : 'availableAmount';
    const theSources = allSources || [];
    return theSources.reduce((sum, source) => {
      sum += source[availableAmountKey];
      return sum;
    }
    , 0);
  };

  const init = async () => {
    setLoading(true);
    try {
      const [portfolioRes, riskRes, sofRes, depositStats] = await Promise.all([fetchPortfolio(), fetchRisk(), fetchSourceOfFunds(), fetchDepositLimit()]);
      const shouldBeHighRisk = typeof riskRes === 'number' && riskRes > 2;
      const depositsAmount = depositStats.deposits;
      setIsHighRisk(shouldBeHighRisk);
      setDeposits(depositsAmount);
      setPortfolio(portfolioRes);
      setSources(sofRes);
      setTotalAvailableAmount(calculateAvailableAmount(sofRes, shouldBeHighRisk));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!loading) {
      setTotalAvailableAmount(calculateAvailableAmount(sources, isHighRisk));
    }
  }, [deposits, sources, loading, isHighRisk]);

  useEffect(() => {
    if (transactionsLoading) {
      setTransaction(false);
    }
    if (!transactionsLoading && transactions && transactions.length > 0) {
      const lastTransaction = transactions[0];
      setTransaction(lastTransaction);
    }
  }, [transactions, transactionsLoading]);

  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const existingTransactionAmount = (transaction && transaction.amount) ? transaction.amount / 100 : 0;
  const totDeposits = deposits + existingTransactionAmount;

  const tabs = [
    {
      component: Create,
      title: `1.${__('new_deposit')}`,
      buttonText: __('next'),
      time: 1,
      forceRender: false,
      componentProps: {
        setTransaction,
        setSelected,
        transaction,
        portfolioUuid,
        portfolio,
        sources,
        isHighRisk: isHighRisk || totDeposits >= 5000000,
        transactionsLoading,
        innerRef: refs.current[0],
        setDisabled,
        setButtonLoading,
        deposits,
        setDeposits,
        totalAvailableAmount,
      }
    },
    {
      component: SourceOfWealth,
      title: `2. Lägg till och uppdatera källor`,
      buttonText: __('next'),
      time: 1,
      forceRender: false,
      componentProps: {
        transaction,
        portfolioUuid,
        sources,
        isHighRisk: isHighRisk || totDeposits >= 5000000,
        loading,
        setSources,
        selected,
        setSelected,
        innerRef: refs.current[1],
        setDisabled,
        setButtonLoading,
        portfolio,
      }
    },
    {
      component: Source,
      title: `3. Välj källor`,
      buttonText: __('next'),
      time: 1,
      forceRender: false,
      componentProps: {
        transaction,
        portfolioUuid,
        sources,
        isHighRisk: isHighRisk || totDeposits >= 5000000,
        loading,
        setSources,
        innerRef: refs.current[2],
        disabled,
        setButtonLoading,
        setSelected,
      }
    },
  ];

  if (transactionsLoading || !portfolio) {
    return (
      <LoadingBox />
    );
  }

  const handleClick = () => {
    refs.current[selected].current.click();
  };

  return (
    <>
      <ProgressTabs
        selected={selected}
        setSelected={setSelected}
        tabs={tabs}
        buttonProps={{
          onClick: handleClick,
          disabled: disabled || buttonLoading,
          children: tabs[selected].buttonText,
          loading: buttonLoading,
          className: 'cta',
          id: 'tutorial-proceed-button',
          icon: 'arrow right 1',
          iconColor: 'white'
        }}
      />
    </>
  );

};

export default Deposit;
